import React, { Component } from 'react'
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Footer from '../../components/footer'
import Layout from '../../components/layout'
import NavSection from '../../components/nav-section'
import { Accordion, Card } from 'react-bootstrap';
import { find } from 'lodash';

class FaqsGuidelines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqsGuidelinesSection: 'active',
      toursFaqsGuidelinesSection: '',
      eventsFaqsGuidelinesSection: '',
      faq: 0,
      faq2: false,
      faq3: false,
      faq4: false,
      faq5: false,
      eventFaq1: false,
      eventFaq2: false,
      eventFaq3: false,
      eventFaq4: false,
      contact1: false,
      contact2: false,
      contact3: false,
      contact4: false,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.sectionScrollHandler);
    }
  }


  sectionScrollHandler = e => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let faqsGuidelinesSectionPosition =
      document.getElementById('faqsGuidelinesSection') &&
      document.getElementById('faqsGuidelinesSection').offsetTop;
    let eventsFaqsGuidelinesSectionPosition =
      document.getElementById('eventsFaqsGuidelinesSection') &&
      document.getElementById('eventsFaqsGuidelinesSection').offsetTop;
    let trustedSuppliersSectionPosition =
      document.getElementById('trustedSuppliersSection') &&
      document.getElementById('trustedSuppliersSection').offsetTop;

    if (winScroll >= faqsGuidelinesSectionPosition) {
      document
        .querySelectorAll('.side-scrollers, .center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('btn-01') &&
        document.getElementById('btn-01').classList.add('active');
      document.getElementById('faqsGuidelinesSectionId') &&
        document.getElementById('faqsGuidelinesSectionId').classList.add('active');
    }
    // if (winScroll >= eventsFaqsGuidelinesSectionPosition) {
    if (winScroll >= eventsFaqsGuidelinesSectionPosition - 200) {
      document
        .querySelectorAll('.side-scrollers, .center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('btn-02') &&
        document.getElementById('btn-02').classList.add('active');
      document.getElementById('eventsFaqsGuidelinesSectionId') &&
        document.getElementById('eventsFaqsGuidelinesSectionId').classList.add('active');
    }
    if (winScroll >= trustedSuppliersSectionPosition - 200) {
      document
        .querySelectorAll('.side-scrollers, .center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('btn-03') &&
        document.getElementById('btn-03').classList.add('active');
      document.getElementById('trustedSuppliersSectionId') &&
        document.getElementById('trustedSuppliersSectionId').classList.add('active');
    }
  };

  scrollHandler = e => {
    this.setState({
      faqsGuidelinesSection: '',
      eventsFaqsGuidelinesSection: '',
      trustedSuppliersSection: '',
    });
    this.setState({ [e.target.dataset.id]: 'active' });
    var target = document.getElementById(e.target.dataset.id);
    target.scrollIntoView(true);
  };

  clickHandler = e => {
    var target = e.currentTarget.dataset.id;
    if(target == this.state.faq){
      target = 0
    }
    this.setState({ faq: target });
  };

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const files = this.props.data.allContentfulDownloads.nodes;
    const toursTerms = find(files, { 'title':'Tours' });
    const eventsTerms = find(files, { 'title':'Events' });
    const accreditedSuppliers = find(files, { 'title':'Accredited Suppliers' });
    const about = get(this, 'props.data.allContentfulAboutFaQs.nodes[0]')
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    const {
      faqsGuidelinesSection,
      eventsFaqsGuidelinesSection,
      trustedSuppliersSection,
      faq,
      faq2,
      faq3,
      faq4,
      faq5,
      eventFaq1,
      eventFaq2,
      eventFaq3,
      eventFaq4,
      contact1,
      contact2,
      contact3,
      contact4
    } = this.state;

    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="FAQ & Guidelines" />

        <section id="faqsGuidelinesSection" className="pb-5">
          <div className="faqGuidelines-header sm-w85">
            <h1>{about.pageTitle}</h1>
            <section id="link-tabs" className="pt-2 pb-5">
              <div className="container sm-w85">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="nav justify-content-center">
                      <li className="font-gtaM">
                        <input
                          className={`center-btns nav-link quick-link ${faqsGuidelinesSection}`}
                          data-id="faqsGuidelinesSection"
                          id="btn-01"
                          onClick={this.scrollHandler}
                          value="01/ TOURS"
                          type="button"
                        />
                      </li>
                      <li className="font-gtaM">
                        <input
                          className={`center-btns nav-link quick-link ${eventsFaqsGuidelinesSection}`}
                          data-id="eventsFaqsGuidelinesSection"
                          id="btn-02"
                          onClick={this.scrollHandler}
                          value="02/ EVENTS"
                          type="button"
                        />
                      </li>
                      <li className="font-gtaM">
                        <input
                          className={`center-btns nav-link quick-link ${trustedSuppliersSection}`}
                          data-id="trustedSuppliersSection"
                          id="btn-03"
                          onClick={this.scrollHandler}
                          value="03/ OUR TRUSTED SUPPLIERS"
                          type="button"
                        />
                      </li>

                      <div className="sidenav">
                        <li className={faqsGuidelinesSection}>
                          <input
                            className={`font-gtaM ${faqsGuidelinesSection} side-scrollers`}
                            data-id="faqsGuidelinesSection"
                            id="faqsGuidelinesSectionId"
                            onClick={this.scrollHandler}
                            value="01/"
                            type="button"
                          />
                        </li>
                        <li className={eventsFaqsGuidelinesSection}>
                          <input
                            className={`font-gtaM ${eventsFaqsGuidelinesSection} side-scrollers`}
                            data-id="eventsFaqsGuidelinesSection"
                            id="eventsFaqsGuidelinesSectionId"
                            onClick={this.scrollHandler}
                            value="02/"
                            type="button"
                          />
                        </li>
                        <li className={trustedSuppliersSection}>
                          <input
                            className={`font-gtaM ${trustedSuppliersSection} side-scrollers`}
                            data-id="trustedSuppliersSection"
                            id="trustedSuppliersSectionId"
                            onClick={this.scrollHandler}
                            value="03/"
                            type="button"
                          />
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <h3 className="mt-5">{about.section1Title}</h3>
          </div>
          <section className="pt-5 pb-5 ">
          {about.section1Faq.map((faqs, i) => (
            <div className="container sm-w85">
              <Accordion className="bordered-bottom">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle data-id={i + 1} eventKey={i + 1} onClick={e => this.clickHandler(e)} variant="link">
                      <div>
                        <div className="nav justify-content-start">
                          <h2 className="font-gtaM red">{faqs.question}</h2>
                        </div>
                      </div>
                      <img alt="expand-icon" src={faq == i + 1 ? '/images/minus-sign.png' : '/images/plus-sign.png'} />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={i + 1}>
                    <div className="nav justify-content-start">
                      <div className="pt-3 pb-1">
                        <p className="font-gtaR mb-0 pb-0 white-space-p-l">
                          {faqs.answer.content[0].content[0].value}
                        </p>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          ))}
          </section>
          <section className="container sm-w85 ">
            <div className="pt-2 pb-5">
              <div className="nav justify-content-center align-items-center">
                <div className="row action-btns mt-3">
                  <a href={toursTerms?.file?.file?.url} download target="_blank">
                    <button className="btn">{about.section1DownloadButton}</button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section id="eventsFaqsGuidelinesSection" className="pt-5 pb-5 mt-0">
          <div className="eventsFaqGuidelines-header">
            <h3 className="mt-5">{about.section2Title}</h3>
          </div>
          <section className="pt-5 pb-5">
            {about.section2Faq.map((faqs, i) => (
              <div className="container sm-w85">
                <Accordion className="bordered-bottom">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle data-id={i + 1} eventKey={i + 1} onClick={e => this.clickHandler(e)} variant="link">
                        <div>
                          <div className="nav justify-content-start">
                            <h2 className="font-gtaM red">{faqs.question}</h2>
                          </div>
                        </div>
                        <img alt="expand-icon" src={faq == i + 1 ? '/images/minus-sign.png' : '/images/plus-sign.png'} />
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i + 1}>
                      <div className="nav justify-content-start">
                        <div className="pt-3 pb-1">
                          <p className="font-gtaR mb-0 pb-0 white-space-p-l">
                            {faqs.answer.content[0].content[0].value}
                          </p>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            ))}
          </section>
          <section className="container sm-w85">
            <div className="pt-2 pb-5">
              <div className="nav justify-content-center align-items-center">
                <div className="row action-btns mt-3">
                  <a href={eventsTerms?.file?.file?.url} download target="_blank">
                    <button className="btn">{about.section2DownloadButton}</button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section id="trustedSuppliersSection" className="pb-5 mb-3 ">
          <div className="trustedSuppliers-header pb-5">
            <h3 className="mt-5">{about.section3TItle}</h3>
          </div>
          <section className="container sm-w85">
            <div className="pt-2 pb-5">
              <p className="font-gtaM red text-center">{about.section3Faq[0].question}</p>
              <div className="nav justify-content-center align-items-center">
                <div className="row action-btns mt-3">
                  <a href={accreditedSuppliers?.file?.file?.url} download target="_blank">
                    <button className="btn">{about.section3DownloadButton}</button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout>
    )
  }

}

export default FaqsGuidelines;

export const pageQuery = graphql`
  query FaqsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulAboutFaQs {
      nodes {
        pageTitle
        section1Title
        section1Faq {
          id
          question
          answer {
            content {
              content {
                value
              }
            }
          }
        }
        section1DownloadButton
        section2Title
        section2Faq {
          id
          question
          answer {
            content {
              content {
                value
              }
            }
          }
        }
        section2DownloadButton
        section3TItle
        section3Faq {
          id
          question
        }
        section3DownloadButton
      }
    }
    allContentfulDownloads {
      nodes {
        file {
          file {
            url
          }
        }
        title
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;